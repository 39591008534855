<div class="flex flex-row justify-content-between" [formGroup]="titlesFG">
  <div class="flex flex-1 flex-column mr-2 p-float-label">
    <input type="text" id="titlesBefore" pInputText formControlName="titlesBefore">
    <label for="titlesBefore">{{ 'missing-certificate-data.titles.titles-before.label' | translate }}</label>
  </div>
  <div class="flex flex-1 flex-column p-float-label">
    <input type="text" id="titlesAfter" pInputText formControlName="titlesAfter">
    <label for="titlesAfter">{{ 'missing-certificate-data.titles.titles-after.label' | translate }}</label>
  </div>
</div>
