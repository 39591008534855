export enum DataType {
  EMAIL = 'EMAIL',
  NAME = 'NAME',
  MAX_COUNT = 'MAX_COUNT',
  DATE = 'DATE',
  BIRTHDATE = 'BIRTHDATE',
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  PHONE_NUMBER = 'PHONE_NUMBER',
  CONSENT = 'CONSENT',
  CHECKBOX = 'CHECKBOX',
  TRI_STATE_CHECKBOX = 'TRI_STATE_CHECKBOX',
  MULTISELECT = 'MULTISELECT',
  BILLING_INFO = 'BILLING_INFO',
  SCROLLING_TEXTAREA = 'SCROLLING_TEXTAREA',
  CONTACT_ADDRESS = 'CONTACT_ADDRESS',
  READONLY_TEXT = 'READONLY_TEXT',
  CUSTOM_TAGS = 'CUSTOM_TAGS',
  TEXT = 'TEXT',
  HTML_TEXT = 'HTML_TEXT',
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  LINK = 'LINK',
  SPECIALIZATION = 'SPECIALIZATION',
  TITLES = 'TITLES'
}